<template>
        <div class="ms_content_wrapper padder_top60">
            <!---Weekly Top 15--->
            <div class="ms_weekly_wrapper">
                <div class="ms_weekly_inner">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ms_heading">
                                <h1 v-for="(audio,indexo) in audios.slice(0,1)" :key="indexo"> {{ this.$route.params.cat_name }} - {{ audio.name }}</h1>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 padding_right40" v-for="(audio,indexo) in audios" :key="indexo">
                            <div class="ms_weekly_box"  >
                                <div class="weekly_left">
                                    <span class="w_top_no">{{indexo + 1}}</span>
                                    <div class="w_top_song" @click="update(audio)">
                                        <div class="w_tp_song_img" @click="selectSound(indexo)">
                                            <img v-lazy="audio.img" alt="" class="img-fluid">
                                            <div class="ms_song_overlay">
                                            </div>
                                            <div class="ms_play_icon" v-if="state.audioPlaying[indexo]">
                                                <div class="ms_bars">
													<div class="bar"></div>
													<div class="bar"></div>
													<div class="bar"></div>
													<div class="bar"></div>
													<div class="bar"></div>
													<div class="bar"></div>
												</div>
                                            </div>
                                            <div class="ms_play_icon" v-else>
                                                <img src="../../assets/images/svg/play.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="w_tp_song_name pointer">
                                            <h3>{{ audio.title_ar }}</h3>
                                            <p>{{ audio.name }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="weekly_right">
                                    <span class="w_song_time">{{ audio.file_duration }}</span>
                                </div>
                                <div class="ms_divider"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <!----Audio Player Section---->
        <div class="ms_player_wrapper" dir="ltr">
			      <div class="ms_player_close">
				        <i class="fa fa-angle-up" aria-hidden="true"></i>
			      </div>
            <div class="player_mid">
                <div class="audio-player">
                    <div id="jquery_jplayer_1" class="jp-jplayer"></div>
                    <div id="jp_container_1" class="jp-audio" role="application" aria-label="media player">
                        <div class="player_left">
                            <div class="ms_play_song">
                                <div class="play_song_name">
                                    <a id="playlist-text">
                                        <div class="jp-now-playing flex-item">
                                            <div class="jp-track-name">
                                                <div class="que_data" v-for="(audio,indexo) in audios.slice(index,index+1)" :key="indexo">
                                                    <span class="que_img">
                                                        <img :src="audio.img" style="width: 50px;">
                                                    </span>{{ audio.title_ar }}<div class="jp-artist-name">{{ audio.name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!----Right Queue---->
                        <div class="jp_queue_wrapper">
                            <span class="que_text" id="myPlaylistQueue"><i class="fa fa-angle-up" aria-hidden="true"></i> قائمة التشغيل</span>
                            <div id="playlist-wrap" class="jp-playlist">
								<div class="jp_queue_cls"><i class="fa fa-times" aria-hidden="true"></i></div>
                                <h2>قائمة التشغيل</h2>
								    <div class="jp_queue_list_inner">
									    <ul>
										    <li  v-for="(audio,indexo) in audios" :key="indexo">
                                                    <!-- <a href="javascript:;" class="jp-playlist-item-remove" style="display: none;">×</a> -->
                                                    <a href="javascript:;" @click="selectSound(indexo)" :style="indexo == index ? '' : ''" :class="indexo == index ? 'shotal':''"  class="jp-playlist-item" tabindex="0">
                                                        <span class="que_img">
                                                            <img :src="audio.img" style="width: 50px;">
                                                        </span>
                                                        <div class="que_data">{{ audio.title_ar }} <span class="jp-artist">{{ audio.desc_ar }}</span></div>
                                                    </a>
                                                    <div class="action">
                                                        <span class="que_close">
                                                            <img src="../../assets/images/svg/close.svg">
                                                        </span>
                                                        <span class="que_more">
                                                            <img src="../../assets/images/svg/more.svg">
                                                        </span>
                                                    </div>
                                            </li>
									    </ul>
								</div>
                            </div>
                        </div>
                        <div class="jp-type-playlist">
                            <div class="jp-gui jp-interface flex-wrap">
                                <div class="jp-controls flex-item">
                                    <button class="jp-previous" tabindex="0" @click="prevButton ? previous() : ''">
					                    <i class="ms_play_control"></i>
				                    </button>
                                    <button class="jp-play" tabindex="0">
					                    <i class="ms_play_control" v-if="!pauseTrack" @click="play()"></i>
                                        <i class=" fa fa-pause-circle fa-3x" style="font-size: 35px;padding: 0 !important;" v-else @click="pause()"></i>
				                    </button>
                                    <button class="jp-next" tabindex="0" @click="nextButton ? next() : ''">
					                    <i class="ms_play_control"></i>
				                    </button>
                                </div>
                                <div class="jp-progress-container flex-item">
                                    <div class="jp-time-holder">
                                        <span class="jp-current-time" role="timer" aria-label="time">&nbsp;{{duration}}</span>
                                        <span class="jp-duration" role="timer" aria-label="duration">&nbsp;{{timer}}</span>
                                    </div>
                                    <div class="jp-progress">
                                        <div class="jp-seek-bar" @click="seek($event)" ref="progress">
                                            <div class="jp-play-bar" :style="{'width' : step + '%'}">
                                                <div class="bullet">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="jp-volume-controls flex-item">
                                    <div class="widget knob-container">
                                        <div class="knob-wrapper-outer" >
                                            <div class="knob-wrapper" >
                                                <div class="knob-mask" >
                                                    <div class="knob d3" ><span></span></div>
                                                    <div class="handle" @click="volume($event)" ref="volBar" ></div>
                                                    <div class="round" :style="{'width' : volumeProgress + '%'}">
                                                        <img src="../../assets/images/svg/volume.svg" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="jp-toggles flex-item">
                                    <button class="jp-shuffle" tabindex="0" title="Shuffle">
									    <i class="ms_play_control" @click="random = !random"></i></button>
									<button class="jp-repeat" tabindex="0" title="Repeat"><i class="ms_play_control"></i></button>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--main div-->
        </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
// import AppFooter from '../Include/AppFooter.vue';
import { reactive, ref } from '@vue/reactivity';
import { Howl, Howler } from 'howler';
import { watch } from '@vue/runtime-core';
export default {
  // components: { AppFooter },
  name: 'AppContents',
  props: {
      content_id: String,
      cat_name: String,
    },

    async setup(props) {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      // const contents = ref([]); 
      
      const audios = ref([]);
      const step =  ref(0);
      const nextButton = ref(true);
      const prevButton = ref(true);
      const random = ref(false);
      const repeat = ref(false);
      const index = ref(0);
      const duration = ref('00:00');
      const timer = ref('00:00');
      const pauseTrack = ref(false);
      const progress = ref(null);
      const volBar = ref(null);
      const sliderBtn = ref(0);
      const sliderBtnVol = ref(null);
      const volumeProgress = ref(90);
      const mutePlayer = ref(false);
      const state = reactive({
        audioPlaying: []
      })


      try {
        await HTTP.get(`GetAllArtistsContent.php?ID=${props.content_id}`).then((res) => {
            audios.value = res.data.ArtistsContent; 
        });
      } catch (err) {
        console.log(err);
      }

      function formatTime(secs) {
          var minutes = Math.floor(secs / 60) || 0;
          var seconds = Math.floor(secs - minutes * 60) || 0;
          return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
      }

        function play() {
            var sound;
            var audio = audios.value[index.value];
            if (audio.howl) {
                sound = audio.howl
            } else {
                state.audioPlaying[index.value] = false;
                sound = audio.howl =  new Howl({
                src: [audio.source],
                html5: true, // A live stream can only be played through HTML5 Audio.
                format: ['mp3', 'aac'],
                onplay: function(){
                    pauseTrack.value = true; 
                    nextButton.value = true;
                    prevButton.value = true;
                    duration.value = formatTime(sound.duration());
                    requestAnimationFrame(stepFunction.bind(this));
                },
                onpause: function(){
                    pauseTrack.value = false;
                },
                onend: function() {
                    next();
                },
                onseek: function(){
                  window.requestAnimationFrame(stepFunction.bind(this));
                }
                });
            }
            sound.play();
            state.audioPlaying[index.value] = true;
        }

        function pause(indexo) {
            
            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.pause();
                pauseTrack.value = false; 
                state.audioPlaying[index.value] = false;   
            }
        }

        function stepFunction() {
            var sound = audios.value[index.value].howl;
            var seek = sound.seek();
            timer.value = formatTime(Math.round(seek)); 
            step.value = (seek * 100) / sound.duration();
            sliderBtn.value = (progress.value.offsetWidth * (step.value/100) + progress.value.offsetWidth * 0.05 - 25);
            if (sound.playing()) {
                window.requestAnimationFrame(stepFunction.bind(this));

            }
        }

        function seek(event) {
            var per =  event.offsetX / progress.value.clientWidth; 
            var sound = audios.value[index.value].howl;
            if (sound) {
                if (sound.playing()) {
                    sound.pause();
                    sound.seek(sound.duration() * per);
                    var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                    sound.play();  
                }else{
                    sound.seek(sound.duration() * per);
                    // var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                }                
            }
        }

        function next() {

            nextButton.value = false;
            var audio = audios.value[index.value].howl;
            state.audioPlaying[index.value] = false;
            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';
            
            if ( audio && audios.value.length - 1 ==  index.value) {
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value = 0
            } else {    
                if (audio) {
                    audio.stop();
                }
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length) 
                :  
                index.value ++;                 
            }
            play();
        } 

        function previous() {

            var audio = audios.value[index.value].howl;
            prevButton.value = false;
            state.audioPlaying[index.value] = false;

            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';

            if (!audio) {
                index.value = audios.value.length - 1;
            } else if (audio && index.value == 0) {
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value = audios.value.length - 1;              
            } else if(audio) {    
                            
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value --;
            } 
            play();
        }

        function selectSound(indexSelected) {

            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.stop();
                state.audioPlaying[index.value] = false;
            }
            index.value = indexSelected;
            play();
        }

        function volume(event) {
    
            var per = event.layerX / parseFloat(volBar.value.scrollWidth);
            var barWidth = (per * 100) / 100;
            volumeProgress.value = barWidth * 100;
            sliderBtnVol.value = (volBar.value.offsetWidth * barWidth + volBar.value.offsetWidth * 0.05 - 25);
            Howler.volume(per);
        }

        function mute() {
            
            var audio = audios.value[index.value].howl;
            if (audio) {
               mutePlayer.value  = !mutePlayer.value;
               mutePlayer.value ? audio.mute(true) : audio.mute(false)
            }
        }

        watch(() => route,(to, from) => {
          pause();
        },{ deep: true });

        const update = (audio) => {
            HTTP.post(`UpdateViews.php?content_id=${audio.id}&msisdn=${cookie.getCookie("msisdn")}`).then((res) => {
                if (res.data.error_code == 1) {
                    console.log('insert secsusse');
                }
            }).catch((error) => {
                console.log(error);
            })
        };
      return {
          play,pause,duration,formatTime,audios,pauseTrack,next,previous,
          index,timer,step,stepFunction,seek,selectSound,state,random,repeat,
          progress,volume,volBar,volumeProgress,sliderBtn,mute,mutePlayer,
          sliderBtnVol,nextButton,prevButton,update
      }
   },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>